import React from "react";
import cx from "classnames";
import Icon from "@components/icon";
import Photo from "@components/photo";
import CustomLink from "@components/link";
import BlockContent from "@components/block-content";
import { m, useAnimation } from "framer-motion";
import useInView from "react-cool-inview";
const IntroText = ({ data = {} }) => {
  const {
    style,
    text,
    cta,
    pricingBlocks,
    pricingAdditionalText,
    backgroundColor,
    image,
    removeMinHeight,
  } = data;

  const controls = useAnimation();

  let rootMargin =
    typeof window !== "undefined"
      ? `${window.outerHeight * -0.4}px 0px`
      : "0px";
  const { observe, unobserve, inView, scrollDirection, entry } = useInView(
    {
      threshold: 0,
      rootMargin: rootMargin,
      onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
        if (scrollDirection.vertical == "up") {
          controls.start("visible");
        }
      },
      // onLeave: ({ scrollDirection, entry, observe, unobserve }) => {
      //   if (scrollDirection.vertical == "down") {
      //     controls.start("hidden");
      //   }
      // },
    },
    [controls]
  );

  if (inView) {
    controls.start("visible");
  }
  const lineVariants = {
    visible: {
      opacity: 1,
      width: "50px",
      transition: { duration: 1 },
    },
    hidden: {
      opacity: 1,
      width: "0px",
      transition: { duration: 1 },
    },
  };
  return (
    <section
      className={cx("intro-text", `bg-${backgroundColor.value}`, {
        "min-h-screen": !removeMinHeight,
      })}
      data-theme={backgroundColor?.title.toLowerCase()}
    >
      <div className={cx("site-container--disabled")}>
        {style == "withPricingBlocks" && (
          <div
            className={cx(
              "grid",
              "grid-cols-4",
              "md:grid-cols-20",
              "p-gutter",
              "py-20",
              "text-left",
              {
                "gap-5": pricingBlocks,
              }
            )}
          >
            <div
              className={cx("col-span-4", "pl-[8px]", {
                "md:col-span-14": !pricingBlocks,
                "md:col-span-12": pricingBlocks,
              })}
            >
              <m.div
                ref={observe}
                initial="hidden"
                variants={lineVariants}
                animate={controls}
                className={cx("border-t", "w-[50px]", "mb-8", {
                  hidden: image,
                })}
              ></m.div>
              <p className={cx("text-lg-2-mobile", "md:text-lg-2")}>{text}</p>
            </div>
            {pricingBlocks && (
              <div
                className={cx(
                  "col-span-4",
                  "md:col-span-7",
                  "md:col-start-14",
                  "mt-10"
                )}
              >
                {pricingBlocks.map((block, idx) => (
                  <div
                    className={cx(
                      "pricing-block",
                      "px-4",
                      "py-6",
                      `bg-${block.backgroundColor.value}`
                    )}
                    key={idx}
                    data-theme={block.backgroundColor?.title.toLowerCase()}
                  >
                    <div
                      className={cx(
                        "text-subheader-2",
                        "uppercase",
                        "font-sansRegular"
                      )}
                    >
                      {block.label}
                    </div>
                    <div className={cx("text-lg-2-mobile", "md:text-lg-2")}>
                      {block.value}
                    </div>
                  </div>
                ))}
                {pricingAdditionalText && (
                  <div
                    className={cx(
                      "px-4",
                      "py-6",
                      "text-caption",
                      "font-sansRegular"
                    )}
                  >
                    <BlockContent blocks={pricingAdditionalText} />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {style == "withDecorativeImage" && (
          <>
            <div
              className={cx(
                "grid",
                "grid-cols-4",
                "md:grid-cols-20",
                "text-left",
                "p-gutterInset"
              )}
            >
              <div className={cx("col-span-4", "md:col-span-14", "py-20")}>
                <m.div
                  ref={observe}
                  initial="hidden"
                  variants={lineVariants}
                  animate={controls}
                  className={cx("border-t", "w-[50px]", "mb-8")}
                ></m.div>
                <p className={cx("text-lg-2-mobile", "md:text-lg-2")}>{text}</p>
              </div>
            </div>
            <div
              className={cx(
                "grid",
                "grid-cols-4",
                "md:grid-cols-20",
                "text-left",
                "mt-10"
              )}
            >
              <div
                className={cx(
                  "col-span-2",
                  "col-start-3",
                  "md:col-span-6",
                  "md:col-start-15"
                )}
              >
                {image && (
                  <div className={cx("col-span-4", "md:col-span-10")}>
                    <Photo photo={image} width={1200} layout="intrinsic" />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {style == "default" && (
          <>
            {image && (
              <div className={cx("p-gutter")}>
                <div
                  className={cx(
                    "grid",
                    "grid-cols-4",
                    "sm:grid-cols-20",
                    "text-left",
                    "bg-white",
                    "rounded-[3px]",
                    "gap-5",
                    "p-gutter"
                  )}
                >
                  {image && (
                    <div
                      className={cx(
                        "col-span-4",
                        "sm:col-span-10",
                        "order-last",
                        "sm:order-first"
                      )}
                    >
                      <Photo photo={image} width={1200} layout="intrinsic" />
                    </div>
                  )}
                  <div
                    className={cx("col-span-4", {
                      "sm:col-span-14 py-20": !image,
                      "sm:col-span-10": image,
                    })}
                  >
                    <div className={cx("h-full")}>
                      <m.div
                        ref={observe}
                        initial="hidden"
                        variants={lineVariants}
                        animate={controls}
                        className={cx("border-t", "w-[50px]", "mb-8", {
                          hidden: image,
                        })}
                      ></m.div>

                      <p
                        className={cx("text-lg-2-mobile", "md:text-lg-2")}
                        dangerouslySetInnerHTML={{
                          __html: text.replace(/(?:\r\n|\r|\n)/g, "<br/>"),
                        }}
                      ></p>
                      {cta && (
                        <CustomLink className="cta-link mt-5" link={cta[0]} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!image && (
              <div
                className={cx(
                  "grid",
                  "grid-cols-4",
                  "md:grid-cols-20",
                  "text-left",
                  {
                    "gap-5": image,
                    "p-gutterInset": !image,
                    "p-gutter": image,
                  }
                )}
              >
                {image && (
                  <div className={cx("col-span-4", "md:col-span-10")}>
                    <Photo photo={image} width={1200} layout="intrinsic" />
                  </div>
                )}
                <div
                  className={cx("col-span-4", {
                    "md:col-span-14 py-20": !image,
                    "md:col-span-6": image,
                  })}
                >
                  <div className={cx("h-full")}>
                    <m.div
                      ref={observe}
                      initial="hidden"
                      variants={lineVariants}
                      animate={controls}
                      className={cx("border-t", "w-[50px]", "mb-8", {
                        hidden: image,
                      })}
                    ></m.div>

                    <p
                      className={cx("text-lg-2-mobile", "md:text-lg-2")}
                      dangerouslySetInnerHTML={{
                        __html: text.replace(/(?:\r\n|\r|\n)/g, "<br/>"),
                      }}
                    ></p>
                    {cta && (
                      <CustomLink className="cta-link mt-5" link={cta[0]} />
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default IntroText;
